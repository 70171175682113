<template>
  <div id="route">
    <!-- 面包屑导航 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/manage">
          <a-icon type="home" />
          <span style="margin-left: 4px;">首页</span>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <span>权限管理</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        路由管理
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div id="main">
      <div class="tableTool">
        <a-button class="add-btn" @click="addRoute">添加</a-button>
      </div>
      <a-table
        id="routeTable"
        :columns="columns"
        :data-source="route_list"
        bordered
        :loading="loading"
        rowKey="id"
        size="middle"
        :pagination="pagination"
        :scroll="{y: scrollY}"
        @change="changePage"
      >
        <span slot='tool' class="btnGroup" slot-scope="text">
          <a-button size="small" @click="() => edit(text)">编辑</a-button>
          <a-popconfirm
            v-if="route_list.length"
            title="确定要删除该路由么？"
            placement="topLeft"
            okText="确定"
            cancelText="取消"
            @confirm="() => del(text.id)"
          >
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <!-- 添加弹框 -->
     <a-modal
      :title="title"
      :centered="true"
      :visible="visible"
      :confirm-loading="confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleCancel"
     >
      <a-form
        :form="routeForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="名称">
          <a-input placeholder="请输入名称" size="default" v-decorator="['name', validatorRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="方法">
          <a-input placeholder="请输入方法" size="default" v-decorator="['http_method', validatorRules.http_method]"></a-input>
        </a-form-item>
        <a-form-item label="URI">
          <a-input placeholder="请输入URI" size="default" v-decorator="['uri', validatorRules.uri]"></a-input>
        </a-form-item>
        <a-form-item label="说明">
          <a-input placeholder="请输入说明" size="default" v-decorator="['remark', validatorRules.remark]"></a-input>
        </a-form-item>
      </a-form>
     </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        columns: [
          {
            title: '序号',
            width: 80,
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          {
            title: '名字',
            dataIndex: 'name'
          },
          {
            title: '方法',
            dataIndex: 'http_method'
          },
          {
            title: 'URI',
            dataIndex: 'uri',
            ellipsis: true
          },
          {
            title: '说明',
            dataIndex: 'remark'
          },
          {
            title: '操作',
            width: 130,
            scopedSlots: { customRender: 'tool' }
          }
        ],
        route_list: [],
        loading: false,
        scrollY: document.documentElement.clientHeight > 812 ?false : document.documentElement.clientHeight - 352, // 表格高度
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.route_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        // 弹框
        title: '',
        visible: false,
        confirmLoading: false,
        routeForm: this.$form.createForm(this, { name: 'routeForm' }),
        validatorRules: {
          name: {
            rules: [
              { required: true, message: '企业名称必填' }
            ]
          },
          http_method: {
            rules: [
              { required: true, message: '企业名称必填' }
            ]
          },
          uri: {
            rules: [
              { required: true, message: '企业名称必填' }
            ]
          },
          remark: {
            rules: []
          }
        },
        id: '',
      }
    },
    methods: {
      getRouteList() {
        this.loading = true;
        this.$http.get('/base/baseroute', {params: {paginate: 0}}).then(res => {
          if(res.data.code == 200){
            this.loading = false;
            this.route_list = res.data.data.rows;
          }
        })
      },
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      addRoute(){
        this.title = '添加路由';
        this.visible = true;
        this.routeForm.resetFields();
      },
      handleOk(){
        this.routeForm.validateFields((err, values) => {
          if(!err){
            this.confirmLoading = true;
            let params = {
              name: values.name,
              uri: values.uri,
              http_method: values.http_method,
              remark: values.remark,
            }
            if(this.id){
              params.id = this.id;
              this.$http.put('/base/baseroute', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.id = '';
                  this.getRouteList();
                  this.confirmLoading = false;
                  this.visible = false;
                }
              })
            }else{
              this.$http.post('/base/baseroute', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getRouteList();
                  this.confirmLoading = false;
                  this.visible = false;
                }
              })
            }
          }
        })
      },
      handleCancel(){
        this.visible = false;
      },
      edit(text){
        this.id = text.id;
        this.title = '编辑路由';
        setTimeout(() => {
          this.routeForm.setFieldsValue({
            name: text.name,
            uri: text.uri,
            http_method: text.http_method,
            remark: text.remark
          })
        }, 50);
        this.visible = true;
      },
      del(id){
        const _this = this;
        this.$confirm({
          'title': '确定要删除该路由么？',
          'content': '删除该路由后可能会导致未知错误',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.delete('/base/baseroute', {params: {id: id}}).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.id = '';
                _this.getRouteList();
              }
            })
          }
        })
      }
    },
    mounted () {
      this.getRouteList();
    },
  }
</script>

<style lang="scss" scoped>
  #route{
    height: 100%;
    // 表格操作
    .tableTool{
      width: 100%;
      padding: 24px 24px 0;
      background-color: white;
      overflow: hidden;
      .add-btn{
        float: right;
      }
    }
    #routeTable{
      background-color: white;
      padding: 24px;
      height: calc(100% - 56px);
      .btnGroup{
        display: flex;
        justify-content: space-around;
      }
    }
  }
</style>