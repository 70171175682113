<template>
  <div id="admin">
    <!-- 面包屑导航 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/manage">
          <a-icon type="home" />
          <span style="margin-left: 4px;">首页</span>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <span>管理员维护</span>
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div id="main">
      <div class="tableTool">
        <a-button class="add-btn" @click="addAdmin">添加</a-button>
      </div>
      <a-table
        id="adminTable"
        :columns="columns"
        :data-source="admin_list"
        bordered
        :loading="loading"
        rowKey="id"
        size="middle"
        :scroll="{y: scrollY}"
      >
        <span slot='tool' class="btnGroup" slot-scope="text">
          <a-button size="small" @click="() => edit(text)">编辑</a-button>
          <a-popconfirm
            v-if="admin_list.length"
            title="确定要删除该管理员么？"
            placement="topLeft"
            okText="确定"
            cancelText="取消"
            @confirm="() => del(text.id)"
          >
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="title"
      :centered="true"
      :visible="visible"
      :confirm-loading="confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        :form="adminForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="用户昵称">
          <a-input placeholder="请输入用户昵称" size="default" v-decorator="['name', validatorRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="用户登录账号">
          <a-input :disabled="id != ''" placeholder="请输入用户登录账号" size="default" v-decorator="['username', validatorRules.username]"></a-input>
        </a-form-item>
        <a-form-item label="密码" v-if="!id">
          <a-input placeholder="请输入密码" size="default" v-decorator="['password', validatorRules.password]"></a-input>
        </a-form-item>
        <a-form-item label="验证密码" v-if="!id">
          <a-input placeholder="请输入验证密码" size="default" v-decorator="['password_confirmation', validatorRules.password_confirmation]"></a-input>
        </a-form-item>
        <a-form-item label="性别">
          <a-select size="default" v-decorator="['sex', validatorRules.sex]" placeholder="请选择请别">
            <a-select-option value="1">男</a-select-option>
            <a-select-option value="2">女</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="手机号">
          <a-input placeholder="请输入手机号" size="default" v-decorator="['phone', validatorRules.phone]"></a-input>
        </a-form-item>
        <a-form-item label="邮箱">
          <a-input placeholder="请输入邮箱" size="default" v-decorator="['email', validatorRules.email]"></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-input placeholder="请输入备注" size="default" v-decorator="['remark', validatorRules.remark]"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        scrollY: document.documentElement.clientHeight > 812 ?false : document.documentElement.clientHeight - 352, // 表格高度
        loading: false,
        admin_list: [],
        columns: [
          {
            title: '序号',
            customRender: (text, record, index) => {
              return index + 1;
            }
          },
          {
            title: '登录名',
            dataIndex: 'username'
          },
          {
            title: '姓名',
            dataIndex: 'name'
          },
          {
            title: '性别',
            dataIndex: 'sex',
            customRender: (text) => {
              return text == 1 ? '男' : '女'
            }
          },
          {
            title: '邮箱',
            dataIndex: 'email',
            ellipsis: true
          },
          {
            title: '手机号',
            dataIndex: 'phone'
          },
          {
            title: '备注',
            dataIndex: 'remark' 
          },
          {
            title: '操作',
            width: 130,
            scopedSlots: { customRender: 'tool' }
          }
        ],
        id: '',
        // 弹框
        title: '',
        visible: false,
        confirmLoading: false,
        adminForm: this.$form.createForm(this, { name: 'adminForm' }),
        validatorRules: {
          name: {
            rules: [
              { required: true, message: '用户昵称必填' }
            ]
          },
          username: {
            rules: [
              { required: true, message: '用户登录账号必填' }
            ]
          },
          password: {
            rules: []
          },
          password_confirmation: {
            rules: []
          },
          sex: {
            rules: [
              { required: true, message: '性别必选' }
            ]
          },
          phone: {
            rules: [
              { required: true, message: '手机号必填' }
            ]
          },
          email: {
            rules: []
          },
          remark: {
            rules: []
          },
        }
      }
    },
    methods: {
      getAdminList(){
        this.loading = true;
        this.$http.get('/administrator', {params: {paginate: 0}}).then(res => {
          if(res.data.code == 200){
            this.loading = false;
            this.admin_list = res.data.data.rows;
          }
        })
      },
      addAdmin() {
        this.title = '添加管理员';
        this.id = '';
        this.adminForm.resetFields();
        this.visible = true;
      },
      handleOk(){
        this.adminForm.validateFields((err, values) => {
          if(!err){
            if(this.id){ // 修改
              this.confirmLoading = true;
              let params = {
                name: values.name,
                username: values.username,
                sex: values.sex,
                phone: values.phone,
                email: values.email,
                remark: values.remark,
                id: this.id
              }
              this.$http.put('/administrator/edit', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.id = '';
                  this.getAdminList();
                  this.confirmLoading = false;
                  this.visible = false;
                }
              })
            }else{
              if(!values.password || !values.password_confirmation || values.password != values.password_confirmation){
                this.$message.error('密码或验证密码填写错误', 1.5)
              }else{
                let params = {
                  name: values.name,
                  username: values.username,
                  password: values.password,
                  password_confirmation: values.password_confirmation,
                  sex: values.sex,
                  phone: values.phone,
                  email: values.email,
                  remark: values.remark,
                }
                this.$http.post('/administrator', params).then(res => {
                  if(res.data.code == 200){
                    this.$message.success('保存成功', 1.5);
                    this.getAdminList();
                    this.confirmLoading = false;
                    this.visible = false;
                  }
                })
              }
            }
          }
        })
      },
      handleCancel(){
        this.visible = false;
        this.id = '';
      },
      edit(text){
        this.id = text.id;
        this.title = '编辑管理员';
        this.visible = true;
        setTimeout(() => {
          this.adminForm.setFieldsValue({
            name: text.name,
            username: text.username,
            sex: text.sex.toString(),
            phone: text.phone,
            email: text.email,
            remark: text.remark,
          })
        }, 50);
      },
      del(id){
        const _this = this;
        this.$confirm({
          'title': '确定要删除该管理员么？',
          'content': '删除管理员后可能会导致未知问题',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.delete('/administrator', {params: {"id": id}}).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.id = '';
                _this.getAdminList();
              }
            })
          }
        })
      }
    },
    mounted () {
      this.getAdminList();
    },
  }
</script>

<style lang="scss" scoped>
  #admin{
    height: 100%;
    .tableTool{
      width: 100%;
      padding: 24px 24px 0;
      background-color: white;
      overflow: hidden;
      .add-btn{
        float: right;
      }
    }
    #adminTable{
      background-color: white;
      padding: 24px;
      height: calc(100% - 56px);
      .btnGroup{
        display: flex;
        justify-content: space-around;
      }
    }
  }
</style>