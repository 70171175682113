<template>
  <div id="farm">
    <!-- 面包屑导航 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/manage">
          <a-icon type="home" />
          <span style="margin-left: 4px;">首页</span>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <span>客户资源</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        养殖场维护
      </a-breadcrumb-item>
    </a-breadcrumb>

    <!-- 主体内容 -->
    <div id="main">
      <div class="tableTool">
        <a-button class="add-btn" @click="addFarm">添加</a-button>
      </div>
      <a-table
        :columns="columns"
        :data-source="farmData"
        bordered
        :loading="loading"
        rowKey="id"
        size="middle"
        :scroll="{y: scrollY}"
        :pagination="pagination"
        @change="changePage"
      >
        <router-link slot="name" slot-scope="text, record" :to="{path: '/manage/farmDetail', query:{farm_id: record.id}}">{{ text }}</router-link>
        <span slot='tool' class="btnGroup" slot-scope="text">
          <a-button size="small" @click="() => edit(text)">编辑</a-button>
          <a-popconfirm
            v-if="companyData.length"
            title="确定要删除该养殖场么？"
            placement="topLeft"
            okText="确定"
            cancelText="取消"
            @confirm="() => del(text.id)"
          >
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </span>
      </a-table>
     </div>

     <!-- 添加弹框 -->
     <a-modal
      :title="title"
      :centered="true"
      :visible="visible"
      :confirm-loading="confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleCancel"
     >
      <a-form
        :form="farmForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="企业名称">
          <a-select v-decorator="['company_id', validatorRules.company_id]" placeholder="请选择企业">
            <a-select-option v-for="item in companyData" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="养殖场名称">
          <a-input placeholder="请输入养殖场名称" size="default" v-decorator="['name', validatorRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="养殖场简称">
          <a-input placeholder="请输入养殖场简称" size="default" v-decorator="['short_name', validatorRules.short_name]"></a-input>
        </a-form-item>
        <a-form-item label="养殖场类型">
          <a-select v-decorator="['category', validatorRules.category]" placeholder="请选择养殖场类型">
            <a-select-opt-group label="养猪场">
              <a-select-option v-for="item in categoryArr" :key="item" :value="item">{{item}}</a-select-option>
            </a-select-opt-group>
            <a-select-opt-group label="养鸡场">
              <a-select-option v-for="item in chickenCategoryArr" :key="item" :value="item">{{item}}</a-select-option>
            </a-select-opt-group>
          </a-select>
        </a-form-item>
        <a-form-item label="省市区">
          <a-cascader :options="positionData" placeholder="请选择省市区" v-decorator="['position', validatorRules.position]"/>
        </a-form-item>
        <a-form-item label="地址">
          <a-input placeholder="请输入地址" size="default" v-decorator="['address', validatorRules.address]"></a-input>
        </a-form-item>
        <a-form-item label="注册日期">
           <a-date-picker v-decorator="['reg_date', validatorRules.reg_date]"/>
        </a-form-item>
      </a-form>
     </a-modal>
  </div>
</template>

<script>
  import position from '../assets/js/city'  
  export default {
    data() {
      return {
        loading: true, // 表格加载图标
        scrollY: document.documentElement.clientHeight > 812 ?false : document.documentElement.clientHeight - 352, // 表格高度
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.farmData.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        columns: [
          {
            title: '序号',
            width: 80,
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          {
            title: '企业',
            dataIndex: 'company_id',
            ellipsis: true,
            sorter: (a, b) => { return a.company_id - b.company_id},
            customRender: (text) => {
              const company = this.companyData.find(item => {
                return item.id == text;
              })
              return company ? company.name : '-'
            }
          },
          {
            title: '名称',
            dataIndex: 'name',
            ellipsis: true,
            scopedSlots: { customRender: 'name' },
          },
          {
            title: '简称',
            dataIndex: 'short_name'
          },
          {
            title: '类型',
            dataIndex: 'category'
          },
          {
            title: '注册时间',
            dataIndex: 'reg_date',
            customRender: text => {
              if(text == 0 || text == null || text == ''){
                return ''
              }
              return this.$moment(text).format('YYYY-MM-DD')
            }
          },
          {
            title: '省份',
            dataIndex: 'province'
          },
          {
            title: '城市',
            dataIndex: 'city'
          },
          {
            title: '县区',
            dataIndex: 'county'
          },
          {
            title: '地址',
            dataIndex: 'address',
            ellipsis: true
          },
          {
            title: '操作',
            width: 130,
            scopedSlots: { customRender: 'tool' }
          }
        ], // 表格列配置
        farmData: [], // 养殖场数据
        companyData: [], // 企业数据
        // 弹框
        title: '', // 弹框标题
        visible: false, // 弹框显示
        confirmLoading: false, // 确认加载
        categoryArr: ['综合场', '繁殖场', '育肥场', '环控'], // 养殖场类型
        chickenCategoryArr: ['养鸡场'], 
        // 表单
        farmForm: this.$form.createForm(this, { name: 'farmForm' }),
        validatorRules: {
          company_id: {
            rules: [
              { required: true, message: '企业必选' }
            ]
          },
          name: {
            rules: [
              { required: true, message: '企业名称必填' }
            ]
          },
          short_name: {
            rules: [
              { required: true, message: '企业简称必填' }
            ]
          },
          category: {
            rules: [
              { required: true, message: '养殖场类型必选' }
            ]
          },
          position: {
            rules: [
              { required: true, message: '省市区必填' }
            ]
          },
          address: {
            rules: [
              { required: true, message: '省市区必填' }
            ]
          },
          reg_date: {
            rules: [
              { required: true, message: '日期必填' }
            ]
          }
        },
        id: '',
        positionData: position,
      }
    },
    methods: {
      // 获取企业列表
      getCompanyList() {
        this.$http.post('/customer/company_list').then(res => {
          if(res.data.code == 200){
            this.companyData = res.data.data[0];
            this.getFarmList();
          }
        })
      },
      // 获取养殖场列表
      getFarmList() {
        this.$http.post('/customer/farm_list').then(res => {
          if(res.data.code == 200){
            this.loading = false;
            this.farmData = res.data.data[0]
          }
        })
      },
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      // 添加养殖场
      addFarm(){
        this.title = '添加养殖场';
        this.visible = true;
        this.farmForm.resetFields();
      },
      // 保存
      handleOk(){
        this.farmForm.validateFields((err, values) => {
          if(!err){
            this.comfirmLoading = true;
            let params = {
              company_id: values.company_id,
              name: values.name,
              short_name: values.short_name,
              category: values.category,
              province: values.position[0],
              city: values.position[1],
              county: values.position[2],
              address: values.address,
              reg_date: this.$moment(values.reg_date).format('YYYY-MM-DD')
            }
            if(this.id){
              params.id = this.id
            }
            this.$http.post('/customer/farm_save', params).then(res => {
              if(res.data.code == 200){
                this.$message.success('保存成功', 1.5);
                this.id = '';
                this.getFarmList();
                this.confirmLoading = false;
                this.visible = false;
              }
            })
          }
        })
      },
      // 取消
      handleCancel(){
        this.visible = false;
      },
      // 编辑
      edit(text){
        this.id = text.id;
        this.title = '编辑养殖场';
        this.visible = true;
        setTimeout(() => {
          this.farmForm.setFieldsValue({
            company_id: text.company_id,
            name: text.name,
            short_name: text.short_name,
            category: text.category,
            position: [text.province, text.city, text.county],
            address: text.address,
            reg_date: this.$moment(text.reg_date * 1000).format('YYYY-MM-DD')
          })
        }, 50);
      },
      // 删除
      del(id){
        const _this = this
        this.$confirm({
          'title': '确定要删除该养殖场么？',
          'content': '删除该企业将一并删除该养殖场下的所有栋舍和环控器等设备',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.post('/customer/farm_delete', {
              id: id
            }).then(res => {
              if(res.data.code == 200){
                _this.getFarmList();
                _this.$message.success('删除成功', 1.5);
              }
            })
          }
        })
      }
      
    },
    mounted () {
      this.getCompanyList();
    },
  }
</script>

<style lang="scss" scoped>
  #farm{
    height: 100%;
    // 表格操作
    .tableTool{
      width: 100%;
      padding: 24px 24px 0;
      background-color: white;
      overflow: hidden;
      .add-btn{
        float: right;
      }
    }
    .ant-table-wrapper{
      background-color: white;
      padding: 24px;
      height: calc(100% - 56px);
      .btnGroup{
        display: flex;
        justify-content: space-around;
      }
    }
  }
</style>