<template>
  <div id="record">
    <!-- 面包屑导航 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/manage">
          <a-icon type="home" />
          <span style="margin-left: 4px;">首页</span>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        日志
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div id="main">
      <a-result title="程序员正在coding……">
        <template #icon>
          <a-icon type="hourglass" theme="twoTone" />
        </template>
      </a-result>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  #record{
    height: 100%;
  }
</style>