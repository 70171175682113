import '@babel/polyfill'
import Vue from 'vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import App from './App.vue'
import './assets/css/common.css'
import store from './store'
import router from './router'
import moment from 'moment'
import md5 from 'js-md5'
import qs from 'qs'
import axios from 'axios'
import { message } from 'ant-design-vue'

Vue.config.productionTip = false

Vue.prototype.$md5 = md5
Vue.prototype.$moment = moment
moment.locale('zh-cn')

Vue.use(Antd)

// axios.defaults.baseURL = 'http://fes_management.com/api/fes_management' // 张奇
// axios.defaults.baseURL = 'http://fes_management.localserver.test:8080/api/fes_management' // 服务器
// axios.defaults.baseURL = 'http://fes_management.homestead.test:8000/api/fes_management' // 王浩然
// axios.defaults.baseURL = 'http://whr.fes_management.test:8000/api/fes_management' // 王浩然
axios.defaults.baseURL = 'http://fes-man.qdpalace.com/api/fes_management' // 正式
// axios.defaults.baseURL = 'http://qiyun_man.com/api/module_admin'
// axios.defaults.baseURL = 'http://qiyun_man1.com/api/module_admin'
// axios.defaults.baseURL = '/api/fes_management'
axios.defaults.timeout = "6000"
import code from './assets/js/code'

const login = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  transformRequest: [data => {
    return qs.stringify(data, {arrayFormat: 'brackets'})
  }]
})
login.interceptors.response.use(response => {
  let reason = code.find(item => {
    return item.code == response.data.code && item.message == response.data.message
  });
  if(reason){
    message.error(reason.reason, 1.5);
  }else if(response.data.code != 200){
    message.error(reason.message);
  }
  return response
})
Vue.prototype.$login = login

const http = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  transformRequest: [data => {
    return qs.stringify(data)
  }]
})
http.interceptors.request.use(config => {
  config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('token');
  return config
})
http.interceptors.response.use(response => {
  let reason = code.find(item => {
    return item.code == response.data.code && item.message == response.data.message
  });
  if(reason){
    message.error(reason.reason, 1.5);
  }else if(response.data.code != 200){
    message.error(reason.message);
  }
  return response
})
Vue.prototype.$http = http;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
