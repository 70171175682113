import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
  },
  mutations: { // 同步操作 commit触发
    setUserInfo(state, userInfo){
      state.userInfo = userInfo
    }
  },
  actions: { // 异步操作 通过context.commit执行修改state dispatch触发

  },
  modules: {

  },
  plugins: [createPersistedState()]
})
