<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  data() {
    return {
      locale: zhCN,
    }
  },
}
</script>

<style lang="scss">
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Avenir,helvetica neue,Arial,Helvetica,sans-serif;
  }
  html, body, #app{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
</style>
