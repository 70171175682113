<template>
  <div id="manage">
    <a-layout id="manage-trigger">
      <!-- 左侧logo+菜单 -->
      <a-layout-sider v-model="collapsed" :trigger="null" collapsible collapsedWidth="48">
        <div id="logo">
          <img src="../assets/logo.svg" alt="logo">
          <h1 v-if="!collapsed">fes 后台管理</h1>
        </div>
        <a-menu
          :selectedKeys="[$router.history.current.path]"
          :open-keys="openKeys"
          mode="inline"
          theme="dark"
          :inline-collapsed="collapsed"
          @openChange="onOpenChange"
          @click="handleClickLink"
          id="manage-menu"
        >
          <a-menu-item key="/manage/home">
            <a-icon type="home" />
            <span>首页</span>
          </a-menu-item>
          <a-sub-menu key="resources" popupClassName="sub-manage">
            <span slot="title"><a-icon type="contacts" /><span>客户资源</span></span>
            <a-menu-item key="/manage/company">
              企业维护
            </a-menu-item>
            <a-menu-item key="/manage/farm">
              养殖场维护
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="equipment">
            <span slot="title"><a-icon type="control" /><span>设备查询</span></span>
            <a-menu-item key="/manage/ecm">
              环控器
            </a-menu-item>
            <a-menu-item key="/manage/video">
              监控视频
            </a-menu-item>
            <a-menu-item key="/manage/tower">
              料塔称重
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="right">
            <span slot="title"><a-icon type="audit" /><span>权限管理</span></span>
            <a-menu-item key="/manage/route">
              路由管理
            </a-menu-item>
            <a-menu-item key="/manage/right">
              权限管理
            </a-menu-item>
            <a-menu-item key="/manage/menu">
              菜单管理
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="system">
            <span slot="title"><a-icon type="setting" /><span>系统配置</span></span>
            <a-menu-item key="/manage/config">
              通用配置
            </a-menu-item>
            <a-menu-item key="/manage/dict">
              字典配置
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item key="/manage/admin">
            <a-icon type="solution" />
            <span>管理员维护</span>
          </a-menu-item>
          <a-menu-item key="/manage/record">
            <a-icon type="file" />
            <span>日志查询</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <!-- 右侧 -->
      <a-layout :style="{height: '100%', overflow: 'hiddem'}">
        <!-- 头部 -->
        <a-layout-header style="background: #fff;padding: 0;">
          <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="() => (collapsed = !collapsed)"></a-icon>
          <a-layout class="header-right">
            <a-dropdown>
              <span id="user">
                <a-icon :type="'user'"></a-icon>{{$store.state.userInfo.username}}
              </span>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;" @click="changePassword">修改密码</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="logout">退出登录</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-layout>
        </a-layout-header>
        <!-- 内容 -->
        <a-layout-content :style="{height: 'calc(100% - 48px)'}">
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>

    <a-modal
      title= '修改密码'
      :centered="true"
      :visible="visible"
      :confirm-loading="confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="handleOK"
      @cancel="handleCancel"
    >
      <a-form
        :form="passwordForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="旧密码">
          <a-input placeholder="请输入旧密码" size="default" v-decorator="['old_password', validatorRules.old_password]"></a-input>
        </a-form-item>
        <a-form-item label="新密码">
          <a-input placeholder="请输入新密码" size="default" v-decorator="['new_password', validatorRules.new_password]"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        collapsed: false,
        openKeys: [],
        rootSubmenuKeys: ['resources', 'equipment', 'right', 'system'],
        visible: false,
        confirmLoading: false,
        passwordForm: this.$form.createForm(this, { name: 'passwordForm' }),
        validatorRules: {
          old_password: {
            rules: [
              { required: true, message: '旧密码必填' }
            ]
          },
          new_password: {
            rules: [
              { new_password: true, message: '新密码必填' }
            ]
          },
        }
      }
    },
    methods: {
      logout() {
        const _this = this;
        this.$confirm({
          title: '提示',
          content: '确认要退出登录么？',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            _this.$http.delete('/oauth/authorizations').then(res => {
              if(res.data.code == 200){
                _this.$message.success('退出登录成功', 1.5);
                _this.$store.commit('setUserInfo', {});
                window.localStorage.removeItem('token')
                _this.$router.push('/');
              }
            })
          },
        })
      },
      changePassword(){
        this.visible = true;
      },
      handleOK(){
        const _this = this;
        this.passwordForm.validateFields((err, values) => {
          if(!err){
            this.$confirm({
              'title': '确定要修改密码么？',
              'content': '修改密码后需重新登录',
              okText: '确定',
              okType: 'danger',
              cancelText: '取消',
              onOk(){
                _this.confirmLoading = true;
                _this.$http.post('/oauth/authorizations/password', {
                  old_password: values.old_password,
                  new_password: values.new_password
                }).then(res => {
                  if(res.data.code == 200){
                    _this.$message.success('修改成功', 1.5);
                    _this.confirmLoading = false;
                    _this.visible = false;
                    _this.$router.push('/')
                  }
                })
              }
            })
          }
        })
      },
      handleCancel(){
        this.visible = false;
      },
      onOpenChange(openKeys) {
        const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.openKeys = openKeys;
        } else {
          this.openKeys = latestOpenKey ? [latestOpenKey] : [];
        }
      },
      // 路由跳转
      handleClickLink(item){
        if(this.$router.currentRoute.fullPath != item.key){
          this.$router.push(item.key)
        }        
      }
    },
    created () {
      switch(this.$router.history.current.path){
        case '/manage/home':
        case '/manage/admin':
        case '/manage/record':
          this.openKeys = [];
          break;
        case '/manage/company':
          this.openKeys = ['resources'];
          break;
        case '/manage/farm':
          this.openKeys = ['resources'];
          break;
        case '/manage/farmDetail':
          this.openKeys = ['resources'];
          break;
        case '/manage/ecm':
          this.openKeys = ['equipment'];
          break;
        case '/manage/video':
          this.openKeys = ['equipment'];
          break;
        case '/manage/tower':
          this.openKeys = ['equipment'];
          break;
        case '/manage/route':
          this.openKeys = ['right'];
          break;
        case '/manage/right':
          this.openKeys = ['right'];
          break;
        case '/manage/menu':
          this.openKeys = ['right'];
          break;
        case '/manage/config':
          this.openKeys = ['system'];
          break;
        case '/manage/dict':
          this.openKeys = ['system'];
          break;
        default:
          break;
      }
    },
  }
</script>

<style lang="scss" scoped>
  @keyframes pro-layout-title-hide {
    0% {
      display: none;
      opacity: 0;
    }
    80% {
      display: none;
      opacity: 0;
    }
    to {
      display: unset;
      opacity: 1;
    }
  }
  #manage{
    height: 100%;
    #manage-trigger{
      height: 100%;
      // 左侧
      #logo{
        position: relative;
        display: flex;
        align-items: center;
        padding: 16px;
        cursor: pointer;
        transition: padding .3s cubic-bezier(.645,.045,.355,1);
        img{
          display: inline-block;
          height: 32px;
          vertical-align: middle;
        }
        h1{
          display: inline-block;
          height: 32px;
          margin: 0 0 0 12px;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          line-height: 32px;
          vertical-align: middle;
          white-space: nowrap;
          animation: pro-layout-title-hide .3s;
        }
      }
      #manage-menu{
        height: calc(100% - 64px);
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar{
          display: none;
        }
      }
      // 窄菜单
      .ant-layout-sider-collapsed{
        #logo{
          padding: 16px 8px;
        }
        .ant-menu-inline-collapsed{
          width: 48px;          
        }
        .ant-menu li{
          padding: 0px!important;
          i{
            padding: 0 calc(50% - 8px)!important;
          }
        }
        .ant-menu-submenu.ant-menu-submenu-vertical div{
          padding: 0!important;
        }
      }
      // 右侧
      .ant-layout-header{
        height: 48px;
        line-height: 48px;
        .trigger{
          line-height: 48px;
          padding: 0 17px;
        }
        // 头部右侧
        .header-right{
          float: right;
          height: 100%;
          padding-right: 20px;
          background: white;
          #user{
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 12px;
            cursor: pointer;
            transition: all .3s;
            font-size: 14px;
            &:hover{
              background: rgba(0,0,0,.025);
            }
            i{
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
</style>