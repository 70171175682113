<template>
  <div id="login">
    <a-layout>
      <a-layout-header></a-layout-header>
      <a-layout-content>
        <!-- 头部 -->
        <div class="login-top">
          <div class="login-header">
            <img src="../assets/logo.svg" alt="logo" class="logo">
            <span class="login-title">fes后台管理</span>
          </div>
          <div class="login-desc">fes后台管理 是fes畜牧养殖系统管理后台</div>
        </div>
        <!-- 表单 -->
        <div class="login-main">
          <a-form :form="loginForm" @submit="login" id="loginForm">
            <a-form-item>
              <a-input placeholder="input admin or user" size="large" v-decorator="['username', validatorRules.username]">
                <a-icon slot="prefix" type="user" style="color:#1890ff" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input-password placeholder="with input password" size="large" v-decorator="['password', validatorRules.password]">
                <a-icon slot="prefix" type="lock" style="color:#1890ff" />
              </a-input-password>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" block size="large">登录</a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-layout-content>
      <a-layout-footer></a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loginForm: this.$form.createForm(this, { name: 'loginForm' }),
        validatorRules: {
          username: {
            rules: [
              { required: true, message: '用户名必填' }
            ]
          },
          password: {
            rules: [
              { required: true, message: '密码必填' },
              { max: 15, min: 5, message: '密码为 5 至 15 位字符' }
            ]
          }
        }
      }
    },
    methods: {
      login(e) {
        e.preventDefault();
        this.loginForm.validateFields((err, values) => {
          if(!err){
            this.$login.post('/oauth/authorizations', {
              'username': values.username,
              'password': values.password,
              'client_id': 1,
              'client_secret': 'HWBSY6DpV9RlcdOsuPhW9v2UcSpJsO3qFxxFVDME',
              'grant_type': 'password'
            }).then(res => {
              if(res.data.code == 200){
                this.$message.success('登录成功', 1.5);
                window.localStorage.setItem('token', res.data.data.access_token)

                // 获取用户信息
                this.getUserInfo();
              }
            })
          }
        })
      },
      getUserInfo(){
        this.$http.post('/administrator/self').then(res => {
          if(res.data.code == 200){
            this.$store.commit('setUserInfo', res.data.data);
            this.$router.push('/manage');
          }
        })
      }
    },
    created(){
      let _this = this;
      document.onkeydown = function(e){
        if(e.key == 'Enter'){
          _this.login(e);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #login{
    width: 100%;
    height: 100%;
    overflow: hidden;
    .ant-layout{
      height: 100%;
      background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center 110px;
      .ant-layout-header{
        background-color: transparent;
      }
      .ant-layout-content{
        padding: 32px 0 24px;
        text-align: center;
        .login-top{
          .login-header{ // 标题+logo
            height: 44px;
            line-height: 44px;
            .logo{
              height: 44px;
              margin-right: 16px;
              vertical-align: top;
            }
            .login-title{
              position: relative;
              top: 2px;
              color: rgba(0,0,0,.85);
              font-weight: 600;
              font-size: 33px;
            }
          }
          .login-desc{
            margin-top: 12px;
            margin-bottom: 40px;
            color: rgba(0,0,0,.45);
            font-size: 14px;
          }
        }
        .login-main{
          width: 328px;
          margin: 0 auto;
        }
      }
    }
  }
</style>