<template>
  <div id="company">
    <!-- 面包屑导航 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/manage">
          <a-icon type="home" />
          <span style="margin-left: 4px;">首页</span>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <span>客户资源</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        企业维护
      </a-breadcrumb-item>
    </a-breadcrumb>

    <!-- 主体内容 -->
    <div id="main">
      <div class="tableTool">
        <a-button class="add-btn" @click="addCompany">添加</a-button>
      </div>
      <a-table
        :columns="columns"
        :data-source="companyData"
        bordered
        :loading="loading"
        rowKey="id"
        size="middle"
        :scroll="{ y: scrollY }"
        :pagination="pagination"
        @change="changePage"
      >
        <span slot='tool' class="btnGroup" slot-scope="text">
          <a-button size="small" @click="() => edit(text)">编辑</a-button>
          <a-popconfirm
            v-if="companyData.length"
            title="确定要删除该企业么？"
            placement="topLeft"
            okText="确定"
            cancelText="取消"
            @confirm="() => del(text.id)"
          >
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <!-- 添加弹框 -->
    <a-modal
      :title="title"
      :centered="true"
      :visible="visible"
      :confirm-loading="confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        :form="companyForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="企业名称">
          <a-input placeholder="请输入企业名称" size="default" v-decorator="['name', validatorRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="企业简称">
          <a-input placeholder="请输入企业简称" size="default" v-decorator="['short_name', validatorRules.short_name]"></a-input>
        </a-form-item>
        <a-form-item label="联系人">
          <a-input placeholder="请输入联系人" size="default" v-decorator="['contact', validatorRules.contact]"></a-input>
        </a-form-item>
        <a-form-item label="电话">
          <a-input placeholder="请输入电话" size="default" v-decorator="['tel', validatorRules.tel]"></a-input>
        </a-form-item>
        <a-form-item label="邮箱">
          <a-input placeholder="请输入邮箱" size="default" v-decorator="['email', validatorRules.email]"></a-input>
        </a-form-item>
        <a-form-item label="省市区">
          <a-cascader :options="positionData" placeholder="请选择省市区" v-decorator="['position', validatorRules.position]"/>
        </a-form-item>
        <a-form-item label="地址">
          <a-input placeholder="请输入地址" size="default" v-decorator="['address', validatorRules.address]"></a-input>
        </a-form-item>
        <a-form-item label="说明">
          <a-input placeholder="请输入说明" size="default" v-decorator="['remarks', validatorRules.remarks]"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import position from '../assets/js/city'  
  export default {
    data() {
      return {
        loading: true, // 表格加载图标
        scrollY: document.documentElement.clientHeight > 812 ?false : document.documentElement.clientHeight - 352, // 表格高度
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.companyData.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        columns: [
          {
            title: '序号',
            width: 80,
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          {
            title: '名称',
            dataIndex: 'name',
            ellipsis: true
          },
          {
            title: '简称',
            dataIndex: 'short_name'
          },
          {
            title: '联系人',
            dataIndex: 'contact'
          },
          {
            title: '电话',
            dataIndex: 'tel',
            ellipsis: true
          },
          {
            title: '邮箱',
            dataIndex: 'email',
            ellipsis: true
          },
          {
            title: '省份',
            dataIndex: 'province'
          },
          {
            title: '城市',
            dataIndex: 'city'
          },
          {
            title: '县区',
            dataIndex: 'county'
          },
          {
            title: '地址',
            dataIndex: 'address',
            ellipsis: true
          },
          {
            title: '说明',
            dataIndex: 'remarks',
            ellipsis: true
          },
          {
            title: '操作',
            width: 130,
            scopedSlots: { customRender: 'tool' }
          }
        ], // 表格列配置
        companyData: [], // 表格数据
        title: '', // 弹框标题
        visible: false, // 弹框显示
        confirmLoading: false, // 确认加载
        // 表单
        companyForm: this.$form.createForm(this, { name: 'companyForm' }),
        validatorRules: {
          name: {
            rules: [
              { required: true, message: '企业名称必填' }
            ]
          },
          short_name: {
            rules: [
              { required: true, message: '企业简称必填' }
            ]
          },
          contact: {
            rules: [
              { required: true, message: '联系人必填' }
            ]
          },
          tel: {
            rules: []
          },
          email: {
            rules: []
          },
          position: {
            rules: [
              { required: true, message: '省市区必填' }
            ]
          },
          address: {
            rules: [
              { required: true, message: '省市区必填' }
            ]
          },
          remarks: {
            rules: []
          },
        },
        id: '',
        positionData: position,
      }
    },
    methods: {
      // 获取企业列表
      getCompanyList() {
        this.$http.post('/customer/company_list').then(res => {
          if(res.data.code == 200){
            this.loading = false;
            this.companyData = res.data.data[0];
          }
        })
      },
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      // 添加企业
      addCompany(){
        this.title = '添加企业'
        this.visible = true;
        this.companyForm.resetFields();
      },
      // 保存
      handleOk() {
        this.companyForm.validateFields((err, values) => {
          if(!err){
            this.confirmLoading = true;
            let params = {
              name: values.name,
              short_name: values.short_name,
              contact: values.contact,
              tel: values.tel ? values.tel : '',
              email: values.email ? values.email : '',
              province: values.position[0],
              city: values.position[1],
              county: values.position[2],
              address: values.address,
              remarks: values.remarks ? values.remarks : ''
            }
            if(this.id){
              params.id = this.id
            }
            this.$http.post('/customer/company_save', params).then(res => {
              if(res.data.code == 200){
                this.$message.success('保存成功', 1.5);
                this.id = '';
                this.getCompanyList();
                this.confirmLoading = false;
                this.visible = false;
              }
            })
          }
        })
      },
      handleCancel() {
        this.visible = false;
      },
      // 编辑
      edit(text) {
        this.id = text.id;
        this.title = '编辑企业'
        setTimeout(() => {
          this.companyForm.setFieldsValue({
            name: text.name,
            short_name: text.short_name,
            contact: text.contact,
            tel: text.tel,
            email: text.email,
            position: [text.province, text.city, text.county],
            address: text.address,
            remarks: text.remarks,
          })
        }, 50);
        this.visible = true;
      },
      // 删除
      del(id) {
        this.$confirm({
          'title': '确定要删除该企业么？',
          'content': '删除该企业将一并删除该企业下的所有猪场以及猪场下的所有栋舍和环控器等设备',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            this.$http.post('/customer/company_delete', {
              id: id
            }).then(res => {
              if(res.data.code == 200){
                this.$message.success('删除成功', 1.5);
                this.id = '';
                this.getCompanyList();
              }
            })
          }
        })
      }
    },
    mounted() {
      this.getCompanyList();
    },
  }
</script>

<style lang="scss" scoped>
  #company{
    // background-color: white;
    // padding: 10px;
    height: 100%;    
    // 表格操作
    .tableTool{
      width: 100%;
      padding: 24px 24px 0;
      background-color: white;
      overflow: hidden;
      .add-btn{
        float: right;
      }
    }
    .ant-table-wrapper{
      background-color: white;
      padding: 24px;
      height: calc(100% - 56px);
      .btnGroup{
        display: flex;
        justify-content: space-around;
      }
    }
  }
</style>