<template>
  <div id="menu">    
    <!-- 面包屑导航 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/manage">
          <a-icon type="home" />
          <span style="margin-left: 4px;">首页</span>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <span>权限管理</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        菜单管理
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div id="main">
      <div class="rightTree">
        <div class="btnGroup">
          <a-button class="add-btn" @click="addMenu">添加</a-button>
          <a-button class="add-btn" type="primary" @click="editMenu">编辑</a-button>
          <a-button class="add-btn" type="danger" @click="delMenu">删除</a-button>
        </div>
        <a-directory-tree :tree-data="menu_tree" @select="onSelect" :show-icon="false"></a-directory-tree>
      </div>
      <div class="rightList">
        <h2>对应权限</h2>
        <a-table
          id="rightTable"
          :columns="columns"
          :data-source="right_list"
          bordered
          :loading="loading"
          rowKey="id"
          size="middle"
          :pagination="pagination"
          @change="changePage"
          :scroll="{y: scrollY}"
          :row-selection="{selectedRowKeys: selectedRowKeys, onChange : changeRoute}"
        >
        </a-table>
        <div class="tableTool">
          <a-button class="add-btn" type="primary" @click="saveMenu">保存</a-button>
          <a-button class="add-btn" type="danger" @click="resetMenu">重置</a-button>
        </div>
      </div>
    </div>

    <a-modal
      :title="title"
      :visible="visible"
      :centered="true"
      :confirm-loading="confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="save"
      @cancel="cancel"
    >
      <a-form
        :form="form"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="上级" v-if="btnEvent == 'add'">
          <a-input size="default" :value="menuCurrent?menuCurrent.title:'无上级'" disabled></a-input>
        </a-form-item>
        <a-form-item label="标题">
          <a-input placeholder="请输入标题" size="default" v-decorator="['title', formRules.title]"></a-input>
        </a-form-item>
        <a-form-item label="URI" v-if="btnEvent == 'add'">
          <a-input placeholder="请输入URI" size="default" v-decorator="['uri', formRules.uri]"></a-input>
        </a-form-item>
        <a-form-item label="icon" v-if="btnEvent == 'add'">
          <a-input placeholder="请选择icon" size="default" v-decorator="['icon', formRules.icon]"></a-input>
        </a-form-item>
        <a-form-item label="排序">
          <a-input placeholder="请输入排序" size="default" v-decorator="['sort_id', formRules.sort_id]"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        menu_list: [],
        menu_tree: [],
        title: '',
        visible: false,
        confirmLoading: false,
        form: this.$form.createForm(this, { name: 'form' }),
        formRules: {
          parent_id: {
            rules: [
              { required: true, message: '上级必选' }
            ]
          },
          title: {
            rules: [
              { required: true, message: '上级必填' }
            ]
          },
          uri: {
            rules: [
              { required: true, message: 'URI必填' }
            ]
          },
          icon: {
            rules: []
          },
          sort_id: {
            rules: []
          }
        },
        menuCurrent: '',
        btnEvent: 'add',
        columns: [
          {
            title: '名字',
            dataIndex: 'name'
          },
          {
            title: '权限分组',
            dataIndex: 'category',
            customRender: text => {
              let category = this.right_category.find(item => {
                return item.value == text;
              })
              return category ? category.name : 'category_err'
            }
          },
          {
            title: '备注',
            dataIndex: 'remark'
          },
        ],
        right_category: [],
        right_list: [],
        loading: false,
        scrollY: document.documentElement.clientHeight > 812 ?false : document.documentElement.clientHeight - 352, // 表格高度
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.right_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        selectedRowKeys: [], // 选中行
      }
    },
    methods: {
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      getRightCategoryList(){
        this.right_category = [];
        this.$http.get('/base/dict', {params: {field: 'right_category'}}).then(res => {
          if(res.data.code == 200){
            this.right_category = res.data.data.rows;
            this.getRightList();
          }
        })
      },
      getRightList(){
        this.right_tree = [];
        this.$http.get('/base/basepermission', {params: {'paginate': 0}}).then(res => {
          if(res.data.code == 200){
            this.right_list = res.data.data.rows;            
          }
        })
      },
      getMenuList(){
        this.menu_tree = [];
        this.$http.get('/base/basemenu').then(res => {
          if(res.data.code == 200){
            this.menu_list = res.data.data.rows;
            this.menu_tree = this.buildTree(this.menu_list, '')
          }
        })
      },
      buildTree(list, parent_id){
        let _this = this;
        let itemArr = [];
        for(let i=0;i<list.length;i++){
          let node = list[i];
          if(node.parent_id == parent_id){
            let newNode = {
              key: node.id,
              title: node.title,
              children: _this.buildTree(list, node.id)
            }
            itemArr.push(newNode);
          }
        }
        return itemArr;
      },
      addMenu() {
        this.btnEvent = 'add';
        this.title = '添加菜单';
        if(!this.menuCurrent){
          this.$message.warning('需先选择一个菜单', 1.5);
        }else{
          this.visible = true;
          this.form.resetFields();
        }
      },
      save(){
        this.form.validateFields((err, values) => {
          if(!err){
            this.confirmLoading = true;
            let params = {
              parent_id: this.btnEvent == 'add'
                ? this.menuCurrent.id
                : this.menuCurrent.parent_id,
              title: values.title,
              icon: values.icon,
              uri: values.uri,
              sort_id: values.sort_id,
            }
            if(this.btnEvent == 'edit'){
              params.id = this.menuCurrent.id;
              this.$http.put('/base/basemenu', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getMenuList();
                  this.confirmLoading = false;
                  this.visible = false;
                }
              })
            }else{
              this.$http.post('/base/basemenu', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getMenuList();
                  this.confirmLoading = false;
                  this.visible = false;
                }
              })
            }
          }
        })
      },
      cancel(){
        this.visible = false;
      },
      editMenu(){
        if(!this.menuCurrent){
          this.$message.warning('需先选择一个菜单', 1.5);
        }else if(this.menuCurrent.id == 1){
          return this.$message.warning('根菜单不可修改', 1.5);
        }else{
          this.btnEvent = 'edit';
          this.title = '编辑菜单';
          this.visible = true;
          setTimeout(() => {
            this.form.setFieldsValue({
              parent_id: this.menuCurrent.parent_id.toString(),
              title: this.menuCurrent.title,
              icon: this.menuCurrent.icon,
              uri: this.menuCurrent.uri,
              sort_id: this.menuCurrent.sort_id
            })
          }, 50);
        }
      },
      delMenu(){
        if(!this.menuCurrent){
          this.$message.warning('需先选择一个菜单', 1.5);
        }else{
          const _this = this;
          this.$confirm({
            "title": '确定要删除该菜单么',
            "content": '删除该菜单会把该菜单下载子菜单一并删除',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk(){
              _this.$http.delete('/base/basemenu', {params: {id: _this.menuCurrent.id}}).then(res => {
                if(res.data.code == 200){
                  _this.$message.success('删除成功', 1.5);
                  _this.menuCurrent = '';
                  _this.confirmLoading = false;
                  _this.getMenuList();
                }
              })
            }
          })
        }
      },
      onSelect(selectedKey){
        this.menuCurrent = this.menu_list.find(item => {
          return item.id == selectedKey
        })
        this.selectedRowKeys = this.menuCurrent.permission
      },
      changeRoute(selectedRowKeys){
        this.selectedRowKeys = selectedRowKeys
      },
      saveMenu(){
        this.$http.post('/base/basemenu/permissions', {
          'id': this.menuCurrent.id,
          'permissions': this.selectedRowKeys
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功', 1.5);
            this.getMenuList();
            this.getRightCategoryList();
          }
        })
      },
      resetMenu(){
        this.selectedRowKeys = [];
      }
    },
    mounted () {
      this.getMenuList();
      this.getRightCategoryList();
    },
  }
</script>

<style lang="scss" scoped>
  #menu{
    height: 100%;
    .rightTree{
      width: 250px;
      float: left;
      height: 100%;
      background-color: white;
      padding: 10px;
      .btnGroup{
        display: flex;
        justify-content: space-between;
      }
      .ant-tree{
        height: calc(100% - 40px);
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar{
          display: none;
        }
      }
      .ant-tree.ant-tree-directory{
        margin-top: 10px;
        padding-bottom: 10px;
      }
    }
    .rightList{
      float: left;
      height: 100%;
      background-color: white;
      width: calc(100% - 270px);
      margin-left: 20px;
      h2{
        padding: 10px 20px;
      }
      #rightTable{
        padding: 0 20px 20px;
        height: calc(100% - 130px);
      }
      .tableTool{
        padding: 20px;
        button{
          margin-right: 10px;
        }
      }
    }
  }
</style>