<template>
  <div id="tower">
    <!-- 面包屑导航 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/manage">
          <a-icon type="home" />
          <span style="margin-left: 4px;">首页</span>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <span>设备查询</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        料塔称重
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div id="main">
      <div class="tableTool">
        <a-form 
          :form="searchForm"
          layout="inline"
          :label-col="{span: 8}"
          :wrapper-col="{span: 14}"
        >
          <a-form-item label="养殖场">
            <a-select v-decorator="['farm_id', validatorRules.farm_id]" placeholder="请选择养殖场" allowClear>
              <a-select-option v-for="item in farm_list" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="设备名称">
            <a-input placeholder="请输入设备名称" size="default" v-decorator="['name', validatorRules.name]"></a-input>
          </a-form-item>
          <a-form-item label="设备ID">
            <a-input placeholder="请输入设备ID" size="default" v-decorator="['box_id', validatorRules.box_id]"></a-input>
          </a-form-item>
          <a-form-item>
            <a-button @click="getTowerList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>

      <a-table
        id="towerTable"
        :columns="columns"
        :data-source="tower_list"
        bordered
        :loading="loading"
        rowKey="id"
        size="middle"
        :scroll="{y: scrollY}"
        :pagination="pagination"
        @change="changePage"
      >

      </a-table>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        company_list: [],
        farm_list: [],
        house_list: [],
        columns: [
          {
            title: '序号',
            width: 80,
            customRender: (text, record, index) => {
              return (this.pagination.current - 1) * this.pagination.pageSize + index + 1;
            }
          },
          {
            title: '设备名称',
            dataIndex: 'name'
          },
          {
            title: '设备ID',
            dataIndex: 'box_id'
          },
          {
            title: '饲料类型',
            dataIndex: 'feed_category'
          },
          {
            title: '饲料名称',
            dataIndex: 'feed_name'
          },
          {
            title: '栋舍',
            dataIndex: 'house_ids',
            ellipsis: true,
            customRender: text => {
              let houseArr = []
              if(text.search(',') == -1){
                if(text){
                  houseArr.push(text);
                }else{
                  return '-'
                }
              }else{
                houseArr = text.split(',');
              }
              let houseStr = '';
              houseArr.forEach(item => {
                let house = this.house_list.find(v => {
                  return v.id == item;
                })
                house ? houseStr += house.name+'，':houseStr+='house_err，'
              })
              return houseStr
            }
          },
          {
            title: '养殖场',
            dataIndex: 'farm_id',
            customRender: (text) => {
              let farm = this.farm_list.find(item => {
                return item.id == text;
              });
              return farm ? farm.name : 'farm_error';
            }
          },
          {
            title: '企业',
            dataIndex: 'company_id',
            customRender: (text) => {
              let company = this.company_list.find(item => {
                return item.id == text;
              });
              return company ? company.name : 'company_error';
            }
          }
        ],
        tower_list: [],
        loading: false,
        scrollY: document.documentElement.clientHeight - 360,  // 表格高度
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.tower_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        searchForm: this.$form.createForm(this, { name: 'searchForm' }),
        validatorRules: {
          farm_id: { rules: [] },
          name: { rules: [] },
          box_id: { rules: [] }
        }
      }
    },
    methods: {
      getCompanyList(){
        this.$http.post('/customer/company_list').then(res => {
          if(res.data.code == 200){
            this.company_list = res.data.data[0];
          }
        })
      },
      getFarmList(){
        this.$http.post('/customer/farm_list').then(res => {
          if(res.data.code == 200){
            this.farm_list = res.data.data[0];
          }
        })
      },
      getHouseList(){
        this.$http.post('/customer/house_list').then(res => {
          if(res.data.code == 200){
            this.house_list = res.data.data[0];
          }
        })
      },
      getTowerList(){
        let params = this.searchForm.getFieldsValue();
        this.loading = true;
        this.$http.post('/device/tower_listing', params).then(res => {
          if(res.data.code == 200){
            this.loading =  false;
            this.tower_list = res.data.data[0];
          }
        })
      },
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
    },
    mounted () {
      this.getCompanyList();
      this.getFarmList();
      this.getHouseList();
      this.getTowerList();
    },
  }
</script>

<style lang="scss" scoped>
  #tower{
    height: 100%;
    .tableTool{
      width: 100%;
      padding: 24px 24px 0;
      background-color: white;
      overflow: hidden;
      .ant-form-item{
        width: 300px;
        &:last-child{
          width: auto;
        }
      }
    }
    #towerTable{
      background-color: white;
      padding: 24px;
      // height: calc(100% - 64px);
      .btnGroup{
        display: flex;
        justify-content: space-around;
      }
    }
  }
</style>