<template>
  <div id="farmDetail">
    <!-- 面包屑导航 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/manage">
          <a-icon type="home" />
          <span style="margin-left: 4px;">首页</span>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <span>客户资源</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link to="/manage/farm">
          <span style="margin-left: 4px;">养殖场维护</span>
        </router-link>        
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        养殖场详情
      </a-breadcrumb-item>
    </a-breadcrumb>

    <!-- 主体内容 -->
    <div id="main">
      <!-- 页头 -->
      <a-page-header :title="farm_detail.name">
        <a-descriptions size="small" :column="3">
          <a-descriptions-item label="简称">
            {{farm_detail.short_name}}
          </a-descriptions-item>
          <a-descriptions-item label="创建时间">
            {{farm_detail.reg_date | formDate}}
          </a-descriptions-item>
          <a-descriptions-item label="地址">
            {{farm_detail.province}}{{farm_detail.city}}{{farm_detail.county}}{{farm_detail.address}}
          </a-descriptions-item>
        </a-descriptions>
      </a-page-header>
      <!-- tab -->
      <a-tabs defaultActiveKey="house" @change="changeTab">
        <!-- 栋舍配置 -->
        <a-tab-pane key="house" tab="栋舍配置">
          <!-- 分组列表 -->
          <a-list size="small" :data-source="group_list" id="group_list" :loading="group_list_loading">
            <a-list-item slot="renderItem" slot-scope="item" :class="group_id == item.id ? 'active' : ''" @click="changeGroup(item.id)">{{item.name}}</a-list-item>
          </a-list>
          <!-- 栋舍列表 -->
          <a-list size="small" :data-source="house_list" id="house_list" :grid="{ column: 6}" :loading="house_list_loading">
            <a-list-item slot="renderItem" slot-scope="item">{{item.name}}</a-list-item>
          </a-list>
        </a-tab-pane>
        <!-- 部门人员 -->
        <a-tab-pane key="staff" tab="部门人员">
          <!-- 部门列表 -->
          <a-list size="small" :data-source="depart_list" id="depart_list" :loading="depart_list_loading">
            <a-list-item slot="renderItem" slot-scope="item" :class="depart_id == item.id ? 'active' : ''" @click="changeGroup(item.id)">{{item.name}}</a-list-item>
          </a-list>
          <a-table :loading="depart_staff_list_loading" bordered :columns="staffCol" :dataSource="depart_staff_list" id="staffTable" size="small"></a-table>
        </a-tab-pane>
        <!-- 环控设备 -->
        <a-tab-pane key="ecm" tab="环控设备">
          <div class="tableTool">
            <a-button class="add-btn" @click="addEcm">添加</a-button>
          </div>
          <a-table :loading="ecm_loading" bordered :columns="ecmCol" :dataSource="ecm_list" id="ecmTable" size="small" :scroll="{y: ecmScrollY}">
            <span slot="ecmTool" slot-scope="text" class='btnGroup'>
              <a-button size="small" @click="() => editEcm(text)">编辑</a-button>
              <a-popconfirm
                v-if="ecm_list.length"
                title="确定要删除该环控设备么？"
                placement="topLeft"
                okText="确定"
                cancelText="取消"
                @confirm="() => delEcm(text.id)"
              >
                <a-button size="small" type="danger">删除</a-button>
              </a-popconfirm>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="video" tab="视频主机">
          <div class="tableTool">
            <a-button class="add-btn" @click="addVideo">添加</a-button>
          </div>
          <a-table :loading="video_loading" bordered :columns="videoCol" :dataSource="video_list" id="videoTable" size="small" :scroll="{y: videoScrollY}">
            <span slot="videoTool" slot-scope="text" class="btnGroup">
              <a-button size="small" @click="() => editVideo(text)">编辑</a-button>
              <a-popconfirm
                v-if="video_list.length"
                title="确定要删除该视频主机么？"
                placement="topLeft"
                okText="确定"
                cancelText="取消"
                @confirm="() => delVideo(text.id)"
              >
                <a-button size="small" type="danger">删除</a-button>
              </a-popconfirm>
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="tower" tab="料塔设备">
          <div class="tableTool">
            <a-button class="add-btn" @click="addTower">添加</a-button>
          </div>
          <a-table :loading="tower_loading" bordered :columns="towerCol" :dataSource="tower_list" id="towerTable" size="small" :scroll="{y: towerScrollY}">
            <span slot="towerTool" slot-scope="text" class="btnGroup">
              <a-button size="small" @click="() => editTower(text)">编辑</a-button>
              <a-popconfirm
                v-if="tower_list.length"
                title="确定要删除该料塔设备么？"
                placement="topLeft"
                okText="确定"
                cancelText="取消"
                @confirm="() => delTower(text.id)"
              >
                <a-button size="small" type="danger">删除</a-button>
              </a-popconfirm>
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 添加环控弹框 -->
    <a-modal
      :title="ecmTitle"
      :centered="true"
      :visible="ecmVisible"
      :confirm-loading="ecmConfirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="ecmOK"
      @cancel="ecmCancel"
    >
      <a-form
        :form="ecmForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="环控器ID">
          <a-input placeholder="请输入环控器ID" size="default" v-decorator="['code', ecmRules.code]"></a-input>
        </a-form-item>
        <a-form-item label="环控器名称">
          <a-input placeholder="请输入环控器名称" size="default" v-decorator="['name', ecmRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="环控器型号">
          <a-select placeholder="请选择环控器型号" size="default" v-decorator="['model', ecmRules.model]">
            <a-select-option v-for="item in ecm_model" :key="item.id" :value="item.value">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 添加视频主机弹框 -->
    <a-modal
      :title="videoTitle"
      :centerrd="true"
      :visible="videoVisible"
      :confirm-loading="videoConfirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="videoOK"
      @cancel="videoCancel"
    >
      <a-form
        :form="videoForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="设备名称">
          <a-input placeholder="请输入设备名称" size="default" v-decorator="['name', videoRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="设备编号">
          <a-input placeholder="请输入设备编号" size="default" v-decorator="['code', videoRules.code]"></a-input>
        </a-form-item>
        <a-form-item label="验证码">
          <a-input placeholder="请输入验证码" size="default" v-decorator="['captcha', videoRules.captcha]"></a-input>
        </a-form-item>
        <a-form-item label="设备型号">
          <a-input placeholder="请输入设备型号" size="default" v-decorator="['model', videoRules.model]"></a-input>
        </a-form-item>
        <a-form-item label="视频路数">
          <a-select v-decorator="['channel', videoRules.channel]">
            <a-select-option value="1">1</a-select-option>
            <a-select-option value="4">4</a-select-option>
            <a-select-option value="8">8</a-select-option>
            <a-select-option value="16">16</a-select-option>
            <a-select-option value="32">32</a-select-option>
            <a-select-option value="64">64</a-select-option>
            <a-select-option value="128">128</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 添加料塔弹框 -->
    <a-modal
      :title="towerTitle"
      :centerrd="true"
      :visible="towerVisible"
      :confirm-loading="towerConfirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="towerOK"
      @cancel="towerCancel"
    >
      <a-form
        :form="towerForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="料塔名称">
          <a-input placeholder="请输入料塔名称" size="default" v-decorator="['name', towerRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="设备编号">
          <a-input placeholder="请输入设备编号" size="default" v-decorator="['box_id', towerRules.box_id]"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data() {
      return {
        farm_id: '',
        farm_detail: {}, // 栋舍详情
        // 栋舍配置
        group_list: [], // 栋舍分组列表
        group_list_loading: false,
        group_id: '', // 选中分组id
        house_list: [], // 栋舍列表
        house_list_loading: false,
        // 部门人员
        depart_list: [], // 部门列表
        depart_list_loading: false,
        depart_id: '', // 部门id
        depart_staff_list: [], // 部门人员列表
        depart_staff_list_loading: false,
        staffCol: [
          {
            title: '序号',
            customRender: (text, record, index) => {
              return index + 1;
            }
          },
          {
            title: '登录名',
            dataIndex: 'user_name'
          },
          {
            title: '姓名',
            dataIndex: 'real_name'
          },
          {
            title: '性别',
            dataIndex: 'gender',
            customRender: (text) => {
              return text == 1 ? '男' : '女';
            }
          },
          {
            title: '邮箱',
            dataIndex: 'email'
          },
          {
            title: 'QQ',
            dataIndex: 'qq'
          },
          {
            title: '状态',
            dataIndex: 'in_use',
            customRender: (text) => {
              return text == 1 ? '在用' : '停用';
            }
          }
        ], // 部门人员列
        // 环控设备
        ecm_loading: false,
        ecm_list: [], // 设备列表
        ecm_model: [], // 环控器型号列表
        ecmCol: [
          {
            title: '序号',
            customRender: (text, record, index) => {
              return index + 1;
            }
          },
          {
            title: '设备编号',
            dataIndex: 'code',
          },
          {
            title: '环控器名称',
            dataIndex: 'name'
          },
          {
            title: '环控器型号',
            dataIndex: 'model',
            customRender: (text) => {
              let ecm =  this.ecm_model.find(item => {
                return item.value == text
              });
              return ecm ? ecm.name : 'ecm_err'
            }
          },
          {
            title: '操作',
            width: 130,
            scopedSlots: { customRender: 'ecmTool' }
          }
        ], // 设备表格列
        ecmScrollY: document.documentElement.clientHeight - 500,
        ecm_id: '',
        // 添加设备弹框
        ecmTitle: '',
        ecmVisible: false,
        ecmConfirmLoading: false,
        ecmForm: this.$form.createForm(this, { name: 'ecmForm' }),
        ecmRules: {
          code: {
            rules: [
              { required: true, message: '环控器ID必填' }
            ]
          },
          name: {
            rules: [
              { required: true, message: '环控器名称必填' }
            ]
          },
          model: {
            rules: [
              { required: true, message: '环控器型号必填' }
            ]
          }
        },
        // 视频主机
        video_loading: false,
        video_list: [],
        videoCol: [
          {
            title: '序号',
            customRender: (text, record, index) => {
              return index + 1;
            }
          },
          {
            title: '设备编号',
            dataIndex: 'code'
          },
          {
            title: '名称',
            dataIndex: 'name'
          },
          {
            title: '验证码',
            dataIndex: 'captcha'
          },
          {
            title: '型号',
            dataIndex: 'model'
          },
          {
            title: '路数',
            dataIndex: 'channel'
          },
          {
            title: '操作',
            width: 130,
            scopedSlots: { customRender: 'videoTool' }
          }
        ],
        videoScrollY: document.documentElement.clientHeight - 500,
        video_id: '',
        videoTitle: '',
        videoVisible: false,
        videoConfirmLoading: false,
        videoForm: this.$form.createForm(this, { name: 'videoForm' }),
        videoRules: {
          name: {
            rules: [
              { required: true, message: '设备名称必填' }
            ]
          },
          code: {
            rules: [
              { required: true, message: '设备编号必填' }
            ]
          },
          captcha: {
            rules: [
              { required: true, message: '验证码必填' }
            ]
          },
          model: {
            rules: [
              { required: true, message: '设备型号必填' }
            ]
          },
          channel: {
            rules: [
              { required: true, message: '设备路数必填' }
            ]
          }
        },
        // 料塔设备
        tower_loading: false,
        tower_list: [],
        feed_category_list: [],
        towerCol: [
          {
            title: '序号',
            customRender: (text, record, index) => {
              return index + 1;
            }
          },
          {
            title: '料塔名称',
            dataIndex: 'name'
          },
          {
            title: '设备编号',
            dataIndex: 'box_id'
          },
          {
            title: '饲料类型',
            dataIndex: 'feed_category',
            customRender: text => {
              let feed_category = this.feed_category_list.find(item => {
                return item.value == text;
              })
              return feed_category ? feed_category.name : 'feed_category_err'
            }
          },
          {
            title: '饲料名称',
            dataIndex: 'feed_name'
          },
          {
            title: '操作',
            width: 130,
            scopedSlots: { customRender: 'towerTool' }
          }
        ],
        towerScrollY: document.documentElement.clientHeight - 500,
        tower_id: '',
        towerTitle: '',
        towerVisible: false,
        towerConfirmLoading: false,
        towerForm: this.$form.createForm(this, { name: 'towerForm' }),
        towerRules: {
          name: {
            rules: [
              { required: true, message: '料塔名称必填' }
            ]
          },
          box_id: {
            rules: [
              { required: true, message: '设备编号必填' }
            ]
          }
        },
      }
    },
    filters: {
      formDate: function(value) {
        return moment(value).format('YYYY-MM-DD');
      }
    },
    methods: {
      getFarmDetail(){
        this.$http.post('/customer/farm_details', {"farm_id": this.farm_id}).then(res => {
          if(res.data.code == 200){
            this.farm_detail = res.data.data[0][0];
          }
        })
      },
      // 栋舍配置
      getGroupList(){
        this.group_list_loading = true;
        this.$http.post('/customer/group_list', {"farm_id": this.farm_id}).then(res => {
          if(res.data.code == 200){
            this.group_list_loading = false;
            this.group_list = res.data.data[0];
            this.group_id = res.data.data[0][0].id;
            this.getHouseList();
          }
        })
      },
      changeGroup(id){
        this.group_id = id;
        this.getHouseList();
      },
      getHouseList(){
        this.house_list_loading = true;
        this.$http.post('/customer/house_list', {"group_id": this.group_id}).then(res => {
          if(res.data.code == 200){
            this.house_list_loading = false;
            this.house_list = res.data.data[0]
          }
        })
      },
      // 部门人员
      getDepartList(){
        this.depart_list_loading = true;
        this.$http.post('/customer/depart_list', {"farm_id": this.farm_id}).then(res => {
          if(res.data.code == 200){
            this.depart_list_loading = false;
            this.depart_list = res.data.data[0];
            this.depart_id = res.data.data[0][0].id;
            this.getDepartStaffList();
          }
        })
      },
      getDepartStaffList(){
        this.depart_staff_list_loading = true;
        this.$http.post('/customer/depart_staff_list', {"depart_id": this.depart_id}).then(res => {
          if(res.data.code == 200){
            this.depart_staff_list_loading = false;
            this.depart_staff_list = res.data.data[0];
          }
        })
      },

      // 环控设备
      getEcmModel(){
        this.$http.get('/base/dict', {params: {field: 'ecmodel'}}).then(res => {
          if(res.data.code == 200){
            this.ecm_model = res.data.data.rows;
          }
        })
      },
      getEcmList(){
        this.$http.post('/customer/ecm_list', {"farm_id": this.farm_id}).then(res => {
          if(res.data.code == 200){
            this.ecm_list = res.data.data[0];
          }
        })
      },
      addEcm(){
        this.ecm_id = '';
        this.ecmTitle = '添加环控设备';
        this.ecmForm.resetFields();
        this.ecmVisible = true;
      },
      editEcm(text){
        this.ecmVisible = true;
        this.ecmTitle = '编辑环控设备';
        this.ecm_id = text.id;
        setTimeout(() => {
          this.ecmForm.setFieldsValue({
            name: text.name,
            code: text.code,
            model: text.model
          })
        }, 50);
      },
      ecmOK(){
        this.ecmForm.validateFields((err, values) => {
          if(!err){
            this.ecmConfirmLoading = true;
            let params = {
              model: values.model,
              name: values.name,
              code: values.code,
              farm_id: this.farm_id
            }
            if(this.ecm_id){
              params.id = this.ecm_id;
            }
            this.$http.post('/customer/ecm_save', params).then(res => {
              if(res.data.code == 200){
                this.$message.success('保存成功', 1.5);
                this.ecm_id = '';
                this.getEcmList();
                this.ecmConfirmLoading = false;
                this.ecmVisible = false;
              }
            })
          }
        })
      },
      ecmCancel(){
        this.ecmVisible = false;
      },
      delEcm(id){
        const _this = this;
        this.$confirm({
          'title': '确定要删除该环控设备么？',
          'content': '删除该环控设备后数据会丢失',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.post('/customer/ecm_delete', {"id": id}).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.ecm_id = '';
                _this.getEcmList();
              }
            })
          }
        })
      },

      // 视频主机
      getVideoList(){
        this.video_loading = true;
        this.$http.post('/customer/video_list', {'farm_id': this.farm_id}).then(res => {
          if(res.data.code == 200){
            this.video_loading = false;
            this.video_list = res.data.data[0];
          }
        })
      },
      addVideo(){
        this.videoTitle = '添加视频主机';
        this.videoForm.resetFields();
        this.videoVisible = true;
      },
      videoOK(){
        this.videoForm.validateFields((err, values) => {
          if(!err){
            this.videoConfirmLoading = true;
            let params = {
              model: values.model,
              name: values.name,
              code: values.code,
              captcha: values.captcha,
              channel: values.channel,
              farm_id: this.farm_id
            }
            if(this.video_id){
              params.id = this.video_id;
            }
            this.$http.post('/customer/video_save', params).then(res => {
              if(res.data.code == 200){
                this.$message.success('保存成功', 1.5);
                this.video_id = '';
                this.getVideoList();
                this.videoConfirmLoading = false;
                this.videoVisible = false;
              }
            })
          }
        })
      },
      videoCancel(){
        this.videoVisible = false;
      },
      editVideo(text){
        this.videoVisible = true;
        this.videoTitle = '编辑视频主机';
        this.video_id = text.id;
        setTimeout(() => {
          this.videoForm.setFieldsValue({
            name: text.name,
            code: text.code,
            model: text.model,
            captcha: text.captcha
          })
        }, 50);
      },
      delVideo(id){
        const _this = this;
        this.$confirm({
          'title': '确定要删除该视频主机么？',
          'content': '删除该视频主机后数据会丢失',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.post('/customer/video_delete', {"id": id}).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.video_id = '';
                _this.getVideoList();
              }
            })
          }
        })
      },

      // 料塔设备
      getFeedCategoryList(){
        this.$http.get('/base/dict', {params: {field: 'feed_category'}}).then(res => {
          if(res.data.code == 200){
            this.feed_category_list = res.data.data.rows;
          }
        })
      },
      addTower(){
        this.towerTitle = '添加料塔';
        this.towerForm.resetFields();
        this.towerVisible = true;
      },
      getTowerList(){
        this.tower_loading = true;
        this.$http.post('/customer/tower_list', {'farm_id': this.farm_id}).then(res => {
          if(res.data.code == 200){
            this.tower_loading = false;
            this.tower_list = res.data.data[0];
          }
        })
      },
      towerOK(){
        this.towerForm.validateFields((err, values) => {
          if(!err){
            this.towerConfirmLoading = true;
            let params = {
              name: values.name,
              box_id: values.box_id,
              farm_id: this.farm_id
            }
            if(this.tower_id){
              params.id = this.tower_id;
            }
            this.$http.post('/customer/tower_save', params).then(res => {
              if(res.data.code == 200){
                this.$message.success('保存成功', 1.5);
                this.tower_id = '';
                this.getTowerList();
                this.towerConfirmLoading = false;
                this.towerVisible = false;
              }
            })
          }
        })
      },
      towerCancel(){
        this.towerVisible = false;
      },
      editTower(text){
        this.towerTitle = '编辑料塔';
        this.tower_id = text.id;
        this.towerVisible = true;
        setTimeout(() => {
          this.towerForm.setFieldsValue({
            name: text.name,
            box_id: text.box_id
          })
        }, 50);
      },
      delTower(id){
        const _this = this;
        this.$confirm({
          'title': '确定要删除该料塔设备么？',
          'content': '删除该料塔设备后数据会丢失',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.post('/customer/tower_delete', {"id": id}).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.towero_id = '';
                _this.getTowerList();
              }
            })
          }
        })
      },
      changeTab(avtiveKey) {
        switch(avtiveKey){
          case 'house': // 栋舍配置
            this.getGroupList();
            break;
          case 'staff': // 部门人员
            this.getDepartList();
            break;
          case 'ecm': // 环控设备
            this.getEcmList();
            break;
          case 'video': // 视频主机
            this.getVideoList();
            break;
          case 'tower': // 料塔设备
            this.getTowerList();
            break;
          default:
            break;
        }
      }
    },
    mounted () {
      this.farm_id = this.$route.query.farm_id;
      this.getFarmDetail();
      this.getGroupList();
      this.getEcmModel();
      this.getFeedCategoryList();
    },
  }
</script>

<style lang="scss" scoped>
  #farmDetail{
    height: 100%;
    // 页头
    .ant-page-header{
      background-color: white;
    }
    // tab
    .ant-tabs{
      background-color: white;
      margin-top: 24px;
      height: calc(100% - 129px);
      .ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content{
        height: calc(100% - 60px)!important;
        #group_list, #depart_list{
          width: 200px;
          float: left;
          li{
            padding-left: 20px;
            cursor: pointer;
            border: none;
            &:hover, &.active{
              background-color: #E6F7FF;
              border-right: 4px solid #1890FF;
            }
          }
        }
        #house_list{
          float: left;
          width: calc(100% - 200px);
          .ant-col.ant-col-4{
            padding-left: 20px;
          }
        }
        // 部门人员表格
        #staffTable{
          float: left;
          width: calc(100% - 200px);
          padding: 0 10px;
        }
        // 环控设备
        .tableTool{
          width: 100%;
          padding: 0 24px;
          background-color: white;
          overflow: hidden;
          .add-btn{
            float: right;
          }
        }
        #ecmTable, #videoTable, #towerTable{
          background-color: white;
          padding: 24px;
          height: calc(100% - 56px);
          .btnGroup{
            width: 100%;
            display: flex;
            justify-content: space-around;
          }
        }
      }
    }
  }
</style>