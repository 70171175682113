<template>
  <div id="right">
    <!-- 面包屑导航 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/manage">
          <a-icon type="home" />
          <span style="margin-left: 4px;">首页</span>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <span>权限管理</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        权限管理
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div id="main">
      <div class="rightTree">
        <div class="btnGroup">
          <a-button class="add-btn" @click="addRight">添加</a-button>
          <a-button class="add-btn" type="primary" @click="editRight">编辑</a-button>
          <a-button class="add-btn" type="danger" @click="delRight">删除</a-button>
        </div>
        <a-directory-tree :tree-data="right_tree" @select="onSelect" :showIcon="false"></a-directory-tree>
      </div>
      <div class="routeList">
        <h2>对应路由</h2>
        <a-table
          id="routeTable"
          :columns="columns"
          :data-source="route_list"
          bordered
          :loading="loading"
          rowKey="id"
          size="middle"
          :pagination="pagination"
          @change="changePage"
          :scroll="{y: scrollY}"
          :row-selection="{selectedRowKeys: selectedRowKeys, onChange : changeRoute}"
        >
        </a-table>
        <div class="tableTool">
          <a-button class="add-btn" type="primary" @click="saveRoute">保存</a-button>
          <a-button class="add-btn" type="danger" @click="resetRoute">重置</a-button>
        </div>
      </div>
    </div>

    <a-modal
      :title="title"
      :centered="true"
      :visible="visible"
      :confirm-loading="confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="save"
      @cancel="cancel"
    >
      <a-form
        :form="rightForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="权限名称">
          <a-input placeholder="请输入权限名称" size="default" v-decorator="['name', rightFormRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="权限分类">
          <a-select placeholder="请选择权限分类" v-decorator="['category', rightFormRules.category]">
            <a-select-option v-for="item in right_category" :key="item.id" :value="item.value">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="权限排序">
          <a-input placeholder="请输入权限排序" size="default" v-decorator="['sort_id', rightFormRules.sort_id]"></a-input>
        </a-form-item>
        <a-form-item label="权限说明">
          <a-input placeholder="请输入权限说明" size="default" v-decorator="['remark', rightFormRules.remark]"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        right_tree: [],
        right_category: [],
        right_list: [],
        visible: false,
        title: '',
        confirmLoading: false,
        rightForm: this.$form.createForm(this, { name: 'rightForm'}),
        rightFormRules: {
          name: {
            rules: [
              { required: true, message: '权限名称必填' }
            ]
          },
          category: {
            rules: [
              { required: true, message: '权限分类必填' }
            ]
          },
          remark: {
            rules: []
          },
          sort_id: {
            rules: []
          },
        },
        rightCurrent: '',
        btnEvent: 'add',
        columns: [
          {
            title: '名字',
            dataIndex: 'name',
            ellipsis: true
          },
          {
            title: '方法',
            dataIndex: 'http_method'
          },
          {
            title: 'URI',
            dataIndex: 'uri',
            ellipsis: true
          },
          {
            title: '说明',
            dataIndex: 'remark'
          },
        ],
        route_list: [],
        loading: false,
        scrollY: document.documentElement.clientHeight > 812 ?false : document.documentElement.clientHeight - 352, // 表格高度
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true, // 显示可改变每页数量
          pageSizeOptions: ['10', '20', '30', '40', '50'], // 每页数量选项
          showTotal: () => `共${this.route_list.length}条记录`, // 显示总数
          showSizeChange: (current, pageSize) => this.pageSize = pageSize, // 改变每页数量时更新显示
        }, // 分页
        selectedRowKeys: [], // 选中行
      }
    },
    methods: {
      // 切换页数
      changePage(pagination){
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = pagination.current;
      },
      getRightCategoryList(){
        this.right_category = [];
        this.$http.get('/base/dict', {params: {field: 'right_category'}}).then(res => {
          if(res.data.code == 200){
            this.right_category = res.data.data.rows;
            this.getRightList();
          }
        })
      },
      getRightList(){
        this.right_tree = [];
        this.$http.get('/base/basepermission', {params: {'paginate': 0}}).then(res => {
          if(res.data.code == 200){
            this.right_list = res.data.data.rows;
            this.right_category.forEach(item => {
              this.right_tree.push({
                key: item.value,
                title: item.name,
                children: []
              })
            })
            for(let i = 0; i < this.right_tree.length; i++){
              res.data.data.rows.forEach(item => {
                if(item.category == this.right_tree[i].key){
                  this.right_tree[i].children.push({
                    key: item.id,
                    title: item.name,
                    isLeaf: true
                  })
                }
              })
            }
          }
        })
      },
      addRight(){
        this.btnEvent = 'add';
        this.title = '添加权限';
        this.visible = true;
        this.rightForm.resetFields();
      },
      save(){
        this.rightForm.validateFields((err, values) => {
          if(!err){
            this.confirmLoading = true;
            let params = {
              name: values.name,
              category: values.category,
              remark: values.remark,
              sort_id: values.sort_id,
            }
            if(this.btnEvent == 'edit'){
              params.id = this.rightCurrent.id;
              this.$http.put('/base/basepermission', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getRightCategoryList();
                  this.confirmLoading = false;
                  this.visible = false;
                }
              })
            }else{
              this.$http.post('/base/basepermission', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getRightCategoryList();
                  this.confirmLoading = false;
                  this.visible = false;
                }
              })
            }
          }
        })
      },
      cancel(){
        this.visible = false;
      },
      editRight(){
        if(!this.rightCurrent){
          this.$message.warning('需先选择一个权限', 1.5);
        }else{
          this.title = '编辑权限';
          this.btnEvent = 'edit'
          this.visible = true;
          setTimeout(() => {
            this.rightForm.setFieldsValue({
              name: this.rightCurrent.name,
              category: this.rightCurrent.category,
              sort_id: this.rightCurrent.sort_id,
              remark: this.rightCurrent.remark,
            })
          }, 50);
        }
      },
      delRight(){
        if(!this.rightCurrent){
          this.$message.warning('需先选择一个权限', 1.5);
        }else{
          const _this = this;
          this.$confirm({
            'title': '确定要删除该权限么',
            'content': '删除该路由后可能会导致未知错误',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk(){
              _this.$http.delete('/base/basepermission', {params: {id: _this.rightCurrent.id}}).then(res => {
                if(res.data.code == 200){
                  _this.$message.success('删除成功', 1.5);
                  _this.rightCurrent = '';
                  _this.getRightCategoryList();
                  _this.getRouteList();
                }
              })
            }
          })
        }
      },
      // 路由列表
      getRouteList() {
        this.loading = true;
        this.$http.get('/base/baseroute', {params: {paginate: 0}}).then(res => {
          if(res.data.code == 200){
            this.loading = false;
            this.route_list = res.data.data.rows;
          }
        })
      },
      onSelect(selectedKey) {
        this.rightCurrent = this.right_list.find(item => {
          return item.id == selectedKey
        })
        if(this.rightCurrent){
          this.selectedRowKeys = this.rightCurrent.route;
        }
      },
      changeRoute(selectedRowKeys){
        this.selectedRowKeys = selectedRowKeys
      },
      saveRoute(){
        this.$http.post('/base/basepermission/routes', {
          "id": this.rightCurrent.id,
          "routes": this.selectedRowKeys
        }).then(res => {
          if(res.data.code == 200){
            this.$message.success('保存成功', 1.5);
            this.getRightCategoryList();
            this.getRouteList();
          }
        })
      },
      resetRoute(){
        this.selectedRowKeys = [];
      }
    },
    mounted () {
      this.getRightCategoryList();
      this.getRouteList();
    },
  }
</script>

<style lang="scss" scoped>
  #right{
    height: 100%;
    .rightTree{
      width: 250px;
      float: left;
      height: 100%;
      background-color: white;
      padding: 10px;
      .btnGroup{
        display: flex;
        justify-content: space-between;
      }
      .ant-tree{
        height: calc(100% - 40px);
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar{
          display: none;
        }
      }
      .ant-tree.ant-tree-directory{
        margin-top: 10px;
        padding-bottom: 10px;
      }
    }
    .routeList{
      float: left;
      height: 100%;
      background-color: white;
      width: calc(100% - 270px);
      margin-left: 20px;
      h2{
        padding: 10px 20px;
      }
      #routeTable{
        padding: 0 20px 20px;
        height: calc(100% - 130px);
      }
      .tableTool{
        padding: 20px;
        button{
          margin-right: 10px;
        }
      }
    }
  }
</style>