<template>
  <div id="dict">
    <!-- 面包屑导航 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/manage">
          <a-icon type="home" />
          <span style="margin-left: 4px;">首页</span>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <span>系统配置</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        字典配置
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div id="main">
      <div class="dictList">
        <h2>
          字典类型
          <a-icon type="plus" @click="addDict" />
        </h2>
        <a-list size="small" :data-source="dict_list" id="dict_list" :loading="dictLoading">
          <a-list-item slot="renderItem" slot-scope="item" :class="dict.id == item.id ? 'active' : ''" @click="changeDict(item)">
            {{item.name}}
            <span>
              <a-icon type="edit" class="edit" @click.stop="dictEdit(item)" />
              <a-icon type="delete" class="del" @click.stop="dictDel(item)" />
            </span>
          </a-list-item>
        </a-list>
      </div>
      <div class="dictValueList">
        <div class="tableTool">
          字典列表
          <a-button class="add-btn" @click="addDictValue">添加</a-button>
        </div>
        <a-list size="small" :data-source="dict_value_list" id="dict_value_list" :loading="dict_value_loading">
          <a-list-item slot="renderItem" slot-scope="item" :class="dict_value_id == item.id ? 'active' : ''">
            {{item.name}}：{{item.value}}
            <span>
              <a-icon type="edit" class="edit" @click="dictValueEdit(item)" />
              <a-icon type="delete" class="del" @click="dictValueDel(item)" />
            </span>
          </a-list-item>
        </a-list>
      </div>
    </div>

    <!-- 字典类型弹框 -->
    <a-modal
      :title="dict_title"
      :centered="true"
      :visible="dict_visible"
      :confirm-loading="dict_confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="dictSave"
      @cancel="dictCancel"
    >
      <a-form
        :form="dictForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="代码">
          <a-input placeholder="请输入代码" size="default" v-decorator="['code', dictFormRules.code]"></a-input>
        </a-form-item>
        <a-form-item label="名称">
          <a-input placeholder="请输入名称" size="default" v-decorator="['name', dictFormRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="排序">
          <a-input placeholder="请输入排序" size="default" v-decorator="['order_num', dictFormRules.order_num]"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 字典列表弹框 -->
    <a-modal
      :title="dict_value_title"
      :centered="true"
      :visible="dict_value_visible"
      :confirm-loading="dict_value_confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="dictValueSave"
      @cancel="dictValueCancel"
    >
      <a-form
        :form="dictValueForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="上级">
          <a-select placeholder="请选择上级" v-decorator="['parent_id', dictValueFormRules.parent_id]">
            <a-select-option value="0">无上级</a-select-option>
            <a-select-option v-for="item in dict_value_list" :key="item.value" :disabled="item.parent_id != 0" :value="item.id.toString()">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="名称">
          <a-input placeholder="请输入名称" size="default" v-decorator="['name', dictValueFormRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="字典值">
          <a-input placeholder="请输入字典值" size="default" v-decorator="['value', dictValueFormRules.value]"></a-input>
        </a-form-item>
        <a-form-item label="排序">
          <a-input placeholder="请输入排序" size="default" v-decorator="['order_num', dictValueFormRules.order_num]"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dict_list: [],
        dictLoading: false,
        dict_id: '',
        dict: '',
        dict_title: '',
        dict_visible: false,
        dict_confirmLoading: false,
        dictForm: this.$form.createForm(this, { name: 'dictForm'}),
        dictFormRules: {
          code: {
            rules: [
              { required: true, message: '代码必填' }
            ]
          },
          name: {
            rules: [
              { required: true, message: '名称必填' }
            ]
          },
          order_num: {
            rules: []
          },
        },
        // 字典列表
        dict_value_list: [],
        dict_value_loading: false,
        dict_value_id: '',
        dict_value_title: '',
        dict_value_visible: false,
        dict_value_confirmLoading: false,
        dictValueForm: this.$form.createForm(this, { name: 'dictValueForm'}),
        dictValueFormRules: {
          parent_id: {
            rules: [
              { required: true, message: '上级必选' }
            ]
          },
          name: {
            rules: [
              { required: true, message: '名称必填' }
            ]
          },
          value: {
            rules: [
              { required: true, message: '字典值必填' }
            ]
          },
          order_num: {
            rules: []
          },
        }
      }
    },
    methods: {
      getDictList(){
        this.dictLoading = true;
        this.$http.get('/base/dictfield', {params: {'paginate': 0}}).then(res => {
          if(res.data.code == 200){
            this.dictLoading = false;
            this.dict_list = res.data.data.rows;
            if(this.dict_list.length){
              this.dict_id = res.data.data.rows[0].id;
              this.dict = res.data.data.rows[0];
              this.getDictValueList(res.data.data.rows[0]);
            }
          }
        })
      },
      changeDict(text){
        this.dict_id = text.id;
        this.dict = text;
        this.getDictValueList(text);
      },
      addDict() {
        this.dict_title = '添加字典类型';
        this.dictForm.resetFields();
        this.dict_visible = true;
        this.dict_id = '';
      },
      dictSave(){
        this.dictForm.validateFields((err, values) => {
          if(!err){
            this.dict_confirmLoading = true;
            let params = {
              code: values.code,
              name: values.name,
              order_num: values.order_num,
            }
            if(this.dict_id){
              params.id = this.dict_id;
              this.$http.put('/base/dictfield', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.dict_id = '';
                  this.getDictList();
                  this.dict_confirmLoading = false;
                  this.dict_visible = false;
                  this.dictForm.resetFields();
                }
              })
            }else{
              this.$http.post('/base/dictfield', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getDictList();
                  this.dict_confirmLoading = false;
                  this.dict_visible = false;
                }
              })
            }
          }
        })
      },
      dictCancel(){
        this.dict_visible = false;
        this.dict_id = this.dict_list[0].id;
      },
      dictEdit(text){
        this.dict_title = '编辑字典类型';
        this.dict_id = text.id;
        this.dict_visible = true;
        setTimeout(() => {
          this.dictForm.setFieldsValue({
            code: text.code,
            name: text.name,
            order_num: text.order_num
          })
        }, 50);
      },
      dictDel(text){
        const _this = this;
        this.$confirm({
          'title': '确定要删除该字典类型么？',
          'content': '删除字典类型后可能会导致未知问题',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.delete('/base/dictfield', {params: {"id": text.id}}).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.dict_id = '';
                _this.getDictList();
              }
            })
          }
        })
      },
      getDictValueList(text){
        this.dict_value_loading = true;
        this.$http.get('/base/dict', {params: {field: text.code,'paginate': 0}}).then(res => {
          if(res.data.code == 200){
            this.dict_value_loading = false;
            this.dict_value_list = res.data.data.rows;
          }
        })
      },
      addDictValue(){
        this.dict_value_title = '添加字典';
        this.dictValueForm.resetFields();
        this.dict_value_visible = true;
      },
      dictValueSave(){
        this.dictValueForm.validateFields((err, values) => {
          if(!err){
            this.dict_value_confirmLoading = true;
            let params = {
              parent_id: values.parent_id,
              name: values.name,
              value: values.value,
              order_num: values.order_num,
              field: this.dict.code
            }
            if(this.dict_value_id){
              params.id = this.dict_value_id;
              this.$http.put('/base/dict', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.dict_value_id = '';
                  this.getDictValueList(this.dict);
                  this.dict_value_confirmLoading = false;
                  this.dict_value_visible = false;
                }
              })
            }else{
              this.$http.post('/base/dict', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getDictValueList(this.dict);
                  this.dict_value_confirmLoading = false;
                  this.dict_value_visible = false;
                }
              })
            }
          }
        })
      },
      dictValueCancel(){
        this.dict_value_visible = false;
      },
      dictValueEdit(text){
        this.dict_value_title = '编辑字典';
        this.dict_value_id = text.id;
        this.dict_value_visible = true;
        setTimeout(() => {
          this.dictValueForm.setFieldsValue({
            parent_id: text.parent_id.toString(),
            name: text.name,
            value: text.value,
            order_num: text.order_num
          })
        }, 50);
      },
      dictValueDel(text){
        const _this = this;
        this.$confirm({
          'title': '确定要删除该字典么？',
          'content': '删除字典后可能会导致未知问题',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.delete('/base/dict', {params: {"id": text.id}}).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.dict_value_id = '';
                _this.getDictValueList(_this.dict);
              }
            })
          }
        })
      }
    },
    mounted () {
      this.getDictList();
    },
  }
</script>

<style lang="scss" scoped>
  #dict{
    height: 100%;
    .dictList{
      width: 200px;
      float: left;
      height: 100%;
      background-color: #F7F7F7;
      padding: 10px;
      h2 i{
        float: right;
        padding: 5px;
        background-color: #2e3e4e;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
      }
      #dict_list{
        width: 100%;
        height: calc(100% - 40px);
        overflow-y: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar{
          display: none;
        }
        li{
          padding-left: 20px;
          cursor: pointer;
          border: none;
          border-right: 4px solid transparent;
          &:hover, &.active{
            background-color: #E6F7FF;
            border-right: 4px solid #1890FF;
          }
          span{
            float: right;
            i{
              margin-right: 10px;
              &.edit:hover{
                color: #40a9ff;
              }
              &.del:hover{
                color: #ff7875;
              }
            }
          }
        }
      }
    }
    .dictValueList{
      float: left;
      width: calc(100% - 220px);
      margin-left: 20px;
      height: 100%;      
      background-color: white;
      .tableTool{
        width: 100%;
        padding: 10px;
        background-color: white;
        overflow: hidden;
        line-height: 31px;
        font-size: 21px;
        .add-btn{
          float: right;
        }
      }
      #dict_value_list{
        width: 100%;
        padding-bottom: 20px;
        overflow: auto;
        background-color: white;
        height: calc(100% - 52px);
        scrollbar-width: none;
        &::-webkit-scrollbar{
          display: none;
        }
        li{
          padding: 8px 20px;
          cursor: pointer;
          // border: none;
          border-right: 4px solid transparent;
          &:hover, &.active{
            background-color: #E6F7FF;
            border-right: 4px solid #1890FF;
          }
          span{
            float: right;
            i{
              margin-left: 20px;
              &.edit:hover{
                color: #40a9ff;
              }
              &.del:hover{
                color: #ff7875;
              }
            }
          }
        } 
      }
    }
  }
</style>