<template>
  <div id="config">
    <!-- 面包屑导航 -->
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/manage">
          <a-icon type="home" />
          <span style="margin-left: 4px;">首页</span>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <span>系统配置</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        通用配置
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div id="main">
      <div class="tableTool">
        <a-button class="add-btn" @click="addConfig">添加</a-button>
      </div>
      <a-table
        id="configTable"
        :columns="columns"
        :data-source="config_list"
        bordered
        :loading="loading"
        rowKey="id"
        size="middle"
        :scroll="{y: scrollY}"
      >
        <span slot='tool' class="btnGroup" slot-scope="text">
          <a-button size="small" @click="() => edit(text)">编辑</a-button>
          <a-popconfirm
            v-if="config_list.length"
            title="确定要删除该配置么？"
            placement="topLeft"
            okText="确定"
            cancelText="取消"
            @confirm="() => del(text.id)"
          >
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="title"
      :centered="true"
      :visible="visible"
      :confirm-loading="confirmLoading"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form
        :form="configForm"
        layout="horizontal"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
      >
        <a-form-item label="名称">
          <a-input placeholder="请输入名称" size="default" v-decorator="['name', validatorRules.name]"></a-input>
        </a-form-item>
        <a-form-item label="数值">
          <a-input placeholder="请输入数值" size="default" v-decorator="['value', validatorRules.value]"></a-input>
        </a-form-item>
        <a-form-item label="说明">
          <a-input placeholder="请输入说明" size="default" v-decorator="['desc', validatorRules.desc]"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        scrollY: document.documentElement.clientHeight > 812 ?false : document.documentElement.clientHeight - 352, // 表格高度
        loading: false,
        columns: [
          {
            title: '序号',
            customRender: (text, record, index) => {
              return index + 1;
            }
          },
          {
            title: '名称',
            dataIndex: 'name'
          },
          {
            title: '数值',
            dataIndex: 'value'
          },
          {
            title: '说明',
            dataIndex: 'desc'
          },
          {
            title: '操作',
            width: 130,
            scopedSlots: { customRender: 'tool' }
          }
        ],
        config_list: [],
        // 弹框
        title: '',
        visible: false,
        confirmLoading: false,
        configForm: this.$form.createForm(this, { name: 'configFomr' }),
        validatorRules: {
          name: {
            rules: [
              { required: true, message: '名称必填' }
            ]
          },
          value: {
            rules: [
              { required: true, message: '数值必填' }
            ]
          },
          desc: {
            rules: []
          },
        },
        id: ''
      }
    },
    methods: {
      getConfigList() {
        this.loading = true;
        this.$http.get('/manage/adminconfig', {params: {'paginate': 0}}).then(res => {
          if(res.data.code == 200){
            this.loading = false;
            this.config_list = res.data.data.rows;
          }
        })
      },
      addConfig(){
        this.title = '添加配置';
        this.configForm.resetFields();
        this.visible = true;
      },
      handleOk(){
        this.configForm.validateFields((err, values) => {
          if(!err){
            this.confirmLoading = true;
            let params = {
              name: values.name,
              value: values.value,
              desc: values.desc
            }
            if(this.id){
              params.id = this.id;
              this.$http.put('/manage/adminconfig', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.id = '';
                  this.getConfigList();
                  this.confirmLoading = false;
                  this.visible = false;
                }
              })
            }else{
              this.$http.post('/manage/adminconfig', params).then(res => {
                if(res.data.code == 200){
                  this.$message.success('保存成功', 1.5);
                  this.getConfigList();
                  this.confirmLoading = false;
                  this.visible = false;
                }
              })
            }
          }
        })
      },
      handleCancel(){
        this.visible = false;
      },
      edit(text){
        this.id = text.id;
        this.title = '编辑配置';
        this.visible = true;
        setTimeout(() => {
          this.configForm.setFieldsValue({
            name: text.name,
            value: text.value,
            desc: text.desc
          })
        }, 50);
      },
      del(id){
        const _this = this;
        this.$confirm({
          'title': '确定要删除该配置么？',
          'content': '删除配置后可能会导致未知问题',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk(){
            _this.$http.delete('/manage/adminconfig', {params: {"id": id}}).then(res => {
              if(res.data.code == 200){
                _this.$message.success('删除成功', 1.5);
                _this.id = '';
                _this.getConfigList();
              }
            })
          }
        })
      }
    },
    mounted () {
      this.getConfigList();
    },
  }
</script>

<style lang="scss" scoped>
  #config{
    height: 100%;
    .tableTool{
      width: 100%;
      padding: 24px 24px 0;
      background-color: white;
      overflow: hidden;
      .add-btn{
        float: right;
      }
    }
    #configTable{
      background-color: white;
      padding: 24px;
      height: calc(100% - 56px);
      .btnGroup{
        display: flex;
        justify-content: space-around;
      }
    }
  }
</style>