import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Manage from '../views/Manage' // 模板
import Home from '../views/Home' // 首页
import Company from '../views/Company' // 企业维护
import Farm from '../views/Farm' // 养殖场维护
import FarmDetail from '../views/FarmDetail' // 养殖场维护
import Ecm from '../views/Ecm' // 环控器
import Video from '../views/Video' // 监控视频
import Tower from '../views/Tower' // 料塔称重
import Route from '../views/Route' // 路由
import Right from '../views/Right' // 权限
import Menu from '../views/Menu' // 菜单
import Config from '../views/Config' // 通用配置
import Dict from '../views/Dict' // 字典配置
import Admin from '../views/Admin' // 管理员维护
import Record from '../views/Record' // 日志维护
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'Login', component: Login},
  { path: '/manage', name: 'Manage', component: Manage, redirect: '/manage/home',
    children: [
      { path: 'home', name: 'Home', component: Home },
      { path: 'company', name: 'Company', component: Company },
      { path: 'farm', name: 'Farm', component: Farm },
      { path: 'farmDetail', name: 'FarmDetail', component: FarmDetail },
      { path: 'ecm', name: 'Ecm', component: Ecm },
      { path: 'video', name: 'Video', component: Video },
      { path: 'tower', name: 'Tower', component: Tower },
      { path: 'route', name: 'Route', component: Route },
      { path: 'right', name: 'Right', component: Right },
      { path: 'menu', name: 'Menu', component: Menu },
      { path: 'config', name: 'Config', component: Config },
      { path: 'dict', name: 'Dict', component: Dict },
      { path: 'admin', name: 'Admin', component: Admin },
      { path: 'record', name: 'Record', component: Record }
    ]
  },
]

const router = new VueRouter({
  routes
})
// 路由拦截
router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('token');
  if (to.path == '/login') return next();
  if( !token ) return next('/login');
  next();
})

export default router
